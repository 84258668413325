import { PortalType } from '@perts/model';

type PortalSettings = {
  portal_type: PortalType | null;
  rule?: string;
  ruleSwitch?: boolean;
  studentIdMax?: number;
  studentIdMin?: number;
  studentIdTerm?: string;
};

export const getPortalMessageFromFormValues = (values: PortalSettings) => {
  const {
    portal_type,
    rule = '',
    ruleSwitch,
    studentIdMax = 0,
    studentIdMin = 0,
    studentIdTerm = '',
  } = values;

  const defaultMessage =
    `Please enter your sign-on ID. Usually that’s your school email ` +
    `address or student ID.`;

  // If the rule feature ("roster ID rule" or "sign-on rule") is not active,
  // the messages are simpler, but still vary by portal type.
  if (!ruleSwitch) {
    if (portal_type === PortalType.CLEVER) {
      return `Select your Clever account.`;
    }
    if (portal_type === PortalType.EMAIL) {
      return `Enter your email address.`;
    }
    if (portal_type === PortalType.GOOGLE) {
      return `Select your Google account.`;
    }
    if (portal_type === PortalType.STUDENT_ID) {
      return `Enter your ${studentIdTerm}. It must be 5 or more digits.`;
    }
    return defaultMessage;
  }

  // When the rule feature is active, the message should include the information
  // needed to sign in successfully.
  if (portal_type === PortalType.CLEVER) {
    // May not be appropriate. See #2587.
    return `Select your Clever account ending with "${rule}"`;
  }
  if (portal_type === PortalType.EMAIL) {
    return `Enter your email address ending with "${rule}"`;
  }
  if (portal_type === PortalType.GOOGLE) {
    return `Select your Google account ending with "${rule}"`;
  }
  if (portal_type === PortalType.STUDENT_ID) {
    return (
      `Enter your ${studentIdTerm}. It must be between ${studentIdMin} and ` +
      `${studentIdMax} digits.`
    );
  }
  return defaultMessage;
};
